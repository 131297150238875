import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import styles from './invite.module.css';

import photo1 from '../../assets/platanos.jpg';

import { FaRegCalendarAlt, FaRegClock, FaMapMarkerAlt } from 'react-icons/fa';
import { SlArrowDown } from "react-icons/sl";


class Invite extends Component {
  state = {};
  render() {
    const text = {};

    text.introText = 'Querida Família e Amigos,';
    text.bodyText = 'Há 4 anos cruzámo-nos em Maastricht e nunca mais nos largámos. Decidimos que seria para sempre e queremos comemorar junto das pessoas mais importantes para nós. O nosso casamento vai realizar-se no dia 21 de Setembro de 2024 às 14h30 na Igreja Paroquial de Olhalvo, seguido de um maravilhoso jantar e uma festa de arromba na Quinta dos Plátanos, em Alenquer.';
    text.confirmationText1 = 'Pedimos, por favor, que confirmem';
    text.confirmationText2 = 'a vossa presença.';
    text.linkText = 'aqui';

    return (
      <div>
        <div>
          <section className={styles.welcomeInviteContainer}>
            <div className={styles.welcomeInviteText}>
              <h4 style={{ fontFamily: "CormorantGaramondRegular" }} className={`${styles.headerText} ${styles.inviteWelcomeText} ${styles.mobileScreenInvite}`}>{'Convite'}</h4>
              <SlArrowDown className={styles.downArrow} />
              <div className={styles.laptopScreenInvite}>
                <p className="subHeaderFontStyle">
                  <b> {text.introText} </b>
                </p>
                <p className="normalFontStyle">{text.bodyText}</p>
                <p className="normalFontStyle">
                  {text.confirmationText1}{' '}
                  <a href={'/confirmation'}>
                    <b>
                      <u>{text.linkText}</u>
                    </b>
                  </a>{' '}
                  {text.confirmationText2}
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className={`${styles.mobileScreenInvite} shadow-sm p-3 mb-5 bg-white rounded`}>
              <p className={styles.subHeaderFontStyle}>
                <b> {text.introText} </b>
              </p>
              <p className={styles.normalFontStyle}>{text.bodyText}</p>
              <p className={styles.normalFontStyle}>
                {text.confirmationText1}{' '}
                <a href={'/confirmation'}>
                  <b>
                    <u>{text.linkText}</u>
                  </b>
                </a>{' '}
                {text.confirmationText2}
              </p>
            </div>
          </section>

          <div style={{ marginTop: '3rem' }}>
            <a href="http://www.quintadosplatanos.com/" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
              <img src={photo1} className={styles.inviteLogo} alt="" />
            </a>

            <section className={styles.inviteInfoContainer}>
              <Container className={styles.inviteInfoContainerElement}>
                <div>
                  <FaRegCalendarAlt className={`${styles.inviteIconStyle} color-primary`} />
                  <h5 className="normalFontStyle">21 de Setembro</h5>
                </div>
              </Container>
              <Container>
                <div>
                  <FaRegClock className={`${styles.inviteIconStyle} color-primary`} />
                  <h5 className="normalFontStyle">14:30</h5>
                </div>
              </Container>
              <Container>
                <a href="https://maps.app.goo.gl/TqWR2sunRQ4exgez6" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                  <div>
                    <FaMapMarkerAlt className={`${styles.inviteIconStyle} color-primary`} />
                    <h5 className="normalFontStyle">Igreja Paroquial de Olhalvo</h5>
                  </div>
                </a>
              </Container>
              <Container>
                <a href="https://maps.app.goo.gl/Zn197tdYEpQCgMe39" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                  <div>
                    <FaMapMarkerAlt className={`${styles.inviteIconStyle} color-primary`} />
                    <h5 className="normalFontStyle">Quinta dos Plátanos, Alenquer</h5>
                  </div>
                </a>
              </Container>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Invite;
