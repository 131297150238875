import React from 'react';
import styles from './burger.module.css';

const Burger = ({ open, setOpen, scrolled }) => {
  let burgerBarClasses;
  if (scrolled) {
    burgerBarClasses = open ? styles.burgerBarClose : styles.burgerBarOpenScrolled;
  } else {
    burgerBarClasses = open ? styles.burgerBarClose : styles.burgerBarOpen;
  }

  return (
    <div className={`${scrolled ? styles.burgerScrolled : styles.burger}`} open={open} onClick={() => setOpen(!open)}>
      <div className={burgerBarClasses} />
      <div className={burgerBarClasses} />
      <div className={burgerBarClasses} />
    </div>
  );
};

export default Burger;
