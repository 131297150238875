import React from 'react';

import './styles.css';

import { SlArrowDown } from 'react-icons/sl';

class Gifts extends React.Component {
  render() {
    const Esmola = {};

    Esmola.introText = 'Querida Família e Amigos,';
    Esmola.bodyText = 'O melhor presente que nos podem oferecer é estarem presentes neste dia tão especial!';
    Esmola.bodyText2 = 'No entanto, caso queiram contribuir para a nossa lua-de-mel ou para nos ajudar a mobilar a nossa casa, deixamos aqui os nossos dados.';
    Esmola.Iban1 = 'IBAN: PT50 0023 0000 45529709166 94';
    Esmola.Mbway1 = 'MBWay: +351 910 047 258';
    Esmola.titular1 = 'Titular: Carlos Loura';
    Esmola.Iban2 = 'IBAN: PT50 0023 0000 45660653346 94';
    Esmola.Mbway2 = 'MBWay: +31 625 388 425';
    Esmola.titular2 = 'Titular: Joana Loureiro';
    Esmola.agradecer = 'Por favor, identifiquem as transferências para que possamos agradecer.';
    Esmola.ty = 'Muito obrigado!';

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let isAndroidPhone = /android/i.test(userAgent);

    return (
      <div>
        <section className={`${isAndroidPhone ? "gifts-welcome-container-android" : ""} gifts-welcome-container`}>
          <div className="gifts-welcome-text">
            <h4
              className="headerText"
              style={{
                fontFamily: 'CormorantGaramondRegular',
              }}
            >
              Lista de Casamento
            </h4>
            <SlArrowDown className="downArrow" />
          </div>
        </section>
        <section>
          <div className="giftsTextbox shadow-sm p-3 mb-5 bg-white rounded">
            <p className="subHeaderFontStyle">{Esmola.introText}</p>
            <p className="normalFontStyle">{Esmola.bodyText}</p>
            <p className="normalFontStyle">{Esmola.bodyText2}</p>

            <p className="normalFontStyle">
              <b> {Esmola.Iban1}</b>
              <br></br>
              <b>{Esmola.titular1}</b>
              <br></br>
              <b>{Esmola.Mbway1}</b>
            </p>

            <p className="normalFontStyle">
              <b> {Esmola.Iban2}</b>
              <br></br>
              <b>{Esmola.titular2}</b>
              <br></br>
              <b>{Esmola.Mbway2}</b>
            </p>

            <p className="normalFontStyle">
              <i>{Esmola.agradecer}</i>
            </p>

            <p className="normalFontStyle">{Esmola.ty}</p>
          </div>
        </section>
      </div>
    );
  }
}

export default Gifts;
