import React, { useState, useEffect } from "react";

function calcDiff(currentDateTime, futureDateTime, lang) {
  var diffInMillis = futureDateTime.getTime() - currentDateTime.getTime(); // TODO CALCULATIONS HERE

  const date = new Date(diffInMillis);
  //   console.log(`Milis diff: ${diffInMillis}`);
  const months = date.getMonth();
  const days = date.getUTCDate() - 1;
  const hrs = date.getUTCHours();
  const mins = date.getUTCMinutes();
  const secs = date.getUTCSeconds();
  // return `${months} meses ${days} dias ${hrs} horas`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{months.toString().padStart(2, "0").padStart(2, "0")}</div>
        <div style={{ color: "white" }} className="commentFontStyle">
          {lang === "en" ? "Months" : "Meses"}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>:</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>{days.toString().padStart(2, "0")}</div>
        <div style={{ color: "white" }} className="commentFontStyle">
          {lang === "en" ? "Days" : "Dias"}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>:</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>{hrs.toString().padStart(2, "0")}</div>
        <div style={{ color: "white" }} className="commentFontStyle">
          {lang === "en" ? "Hours" : "Horas"}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>:</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>{mins.toString().padStart(2, "0")}</div>
        <div style={{ color: "white" }} className="commentFontStyle">
          {lang === "en" ? "Minutes" : "Minutos"}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>:</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div>{secs.toString().padStart(2, "0")}</div>
        <div style={{ color: "white" }} className="commentFontStyle">
          {lang === "en" ? "Seconds" : "Segundos"}
        </div>
      </div>
    </div>
  );
}

const CountdownTimer = ({ dateTo, styles, classes, headerClasses, props }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [minutesDiff, setMinutesDiff] = useState(
    calcDiff(currentDate, dateTo, props.lang)
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [currentDate]);

  useEffect(() => {
    setMinutesDiff(calcDiff(currentDate, dateTo, props.lang));
  }, [currentDate, dateTo, props.lang]);

  //   const weddingDate = new Date("2022-05-28T12:00:00");
  //   const diff = calcDiff(new Date(), weddingDate);

  return (
    <div>
      {/* <div className={headerClasses}>Countdown</div>{" "} */}
      <div className={classes}>{minutesDiff}</div>
    </div>
  );
};

export default CountdownTimer;
