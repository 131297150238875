import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

// import { init } from "emailjs-com";
import emailjs from 'emailjs-com';
import './styles.css';

const maxGuests = 4;

const userId = 'c2qnm-ewvadpUHYc4';
const emailServiceId = 'service_mz4648f';
const templateIdGoing = 'template_zxjtkgv';
const templateIdNotGoing = 'template_ijswa8c';

const waitingStatus = 'waiting';
const sendingStatus = 'sending';
const sentStatus = 'sent';
const failedStatus = 'failed';

class Confirmation extends Component {
  state = {
    language: '',
    chose: false,
    isGoing: false,
    address: '',
    postalCode: '',
    country: '',
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    foodText: '',
    songText: '',
    invites: [],
    sendStatus: waitingStatus,
  };

  language = this.props.lang;

  handleAddNames = () => {
    console.log(`HandleAddNames lang: ${this.props.lang}`);
    const index = this.state.invites.length;
    const key = `invite${index}`;
    const firstName = `firstName${index}`;
    const lastName = `lastName${index}`;

    const newElement = (
      <div key={key} className="form-row">
        <div className="col">
          <Form.Group controlId={firstName}>
            <Form.Label>{this.props.lang === 'en' ? 'Name' : 'Nome'}</Form.Label>
            <Form.Control
              // onChange={this.handleFirstNameChange}
              type="text"
              name={firstName}
              placeholder={this.props.lang === 'en' ? 'Add name' : 'Adicionar nome'}
            />
          </Form.Group>
        </div>

        <div className="col">
          <Form.Group controlId={lastName}>
            <Form.Label>{this.props.lang === 'en' ? 'Surname' : 'Apelido'}</Form.Label>
            <Form.Control
              // onChange={this.handleLastNameChange}
              type="text"
              name={lastName}
              placeholder={this.props.lang === 'en' ? 'Add surname' : 'Adicionar apelido'}
            />
          </Form.Group>
        </div>
      </div>
    );
    this.setState((prevState) => ({
      invites: [...(prevState.invites || []), newElement],
    }));

    // console.log(`Added new invitee, total: ${this.state.invites.length}`);
  };

  handleRemoveNames = () => {
    let invites = this.state.invites;
    let newInvites = [];

    for (let i = 0; i < invites.length - 1; i++) {
      newInvites.push(invites[i]);
    }

    this.setState({ invites: newInvites }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleFoodTextChange = (event) => {
    this.setState({ foodText: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleSongTextChange = (event) => {
    this.setState({ songText: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleAddressChange = (event) => {
    this.setState({ address: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleCityChange = (event) => {
    this.setState({ city: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handlePostalCodeChange = (event) => {
    this.setState({ postalCode: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleCountryChange = (event) => {
    // console.log(event);
    this.setState({ country: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ sendStatus: sendingStatus }, () => {
      let guests = [];
      for (let i = 0; i < maxGuests; i++) {
        const element = this.state[`inviteNames${i}`];

        if (element) {
          guests.push(element);
        }
      }

      guests.forEach((el, i) => {
        if (i === 0) console.log('How many? - ' + guests.length);
        console.log(`Name of extra guest ${i} -> ` + guests[i]);
      });

      emailjs.sendForm(emailServiceId, this.state.isGoing === true ? templateIdGoing : templateIdNotGoing, event.target, userId).then(
        (result) => {
          console.log(result);
          this.setState({ sendStatus: sentStatus }, () => { });
        },
        (error) => {
          console.log(error.text);
          window.alert('Ocorreu um erro. Por favor contacte os noivos.');
          this.setState({ sendStatus: failedStatus }, () => { });
        }
      );
    });
  };

  handleInviteNamesChange = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () => {
      // console.log("new comment: " + this.state.comments);
    });
  };

  isSubmitDisabled = () => {
    const state = this.state;
    if (state.isGoing === true) {
      return !(
        state.lastName.length > 0 &&
        state.email.length > 0 &&
        state.firstName.length > 0 &&
        // state.foodText.length > 0 &&
        // state.songText.length > 0 &&
        state.country.length > 0 &&
        state.city.length > 0 &&
        state.address.length > 0 &&
        state.postalCode.length > 0
      );
    } else {
      return !(state.lastName.length > 0 && state.email.length > 0 && state.firstName.length > 0 && state.chose === true);
    }
  };

  handleIsGoingChange = (event) => {
    this.setState({ chose: true }, () => {
      if (event.target.value === 'true') {
        this.setState({ isGoing: true }, () => { });
      } else {
        this.setState({ isGoing: false }, () => {
          this.setState({ songText: '' }, () => {
            this.setState({ foodText: '' }, () => { });
          });
        });
      }
    });
  };

  renderSubmitButton = () => {
    switch (this.state.sendStatus) {
      case waitingStatus:
        return 'Submeter';
      case sendingStatus:
        return (
          <div className="loadingDots">
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
          </div>
        );
      case sentStatus:
        return 'Obrigado!';

      case failedStatus:
        return 'Erro!';

      default:
        break;
    }
  };

  render() {
    // const language = this.props.lang;
    const countryList = require('country-list');
    const countries = countryList.getNames();
    return (
      <div>
        <section className="confirmation-welcome-container">
          <div className="confirmation-welcome-text">
            <h4
              className="headerText"
              style={{
                fontFamily: 'CormorantGaramondRegular',
              }}
            >
              {this.language === 'en' ? 'Confirm your attendance!' : 'Confirma a tua presença!'}
            </h4>
          </div>
        </section>
        <section className="formSection">
          <Form className="formStyle" onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="col">
                <Form.Group controlId="firstName">
                  <Form.Label>{this.language === 'en' ? 'Name' : 'Nome'}</Form.Label>
                  <Form.Control defaultValue={this.state.firstName} onChange={this.handleFirstNameChange} type="text" name="firstName" placeholder={this.language === 'en' ? 'Add name' : 'Adicionar nome'} />
                </Form.Group>
              </div>

              <div className="col">
                <Form.Group controlId="lastName">
                  <Form.Label>{this.language === 'en' ? 'Surname' : 'Apelido'}</Form.Label>
                  <Form.Control defaultValue={this.state.lastName} onChange={this.handleLastNameChange} type="text" name="lastName" placeholder={this.language === 'en' ? 'Add surname' : 'Adicionar apelido'} />
                </Form.Group>
              </div>
            </div>
            {this.state.invites}
            <div className="manageInviteButtons">
              <Button disabled={this.state.invites.length === maxGuests} className="manageInviteButtonStyle" variant="outline-primary" size="sm" onClick={this.handleAddNames}>
                +
              </Button>
              <Button disabled={this.state.invites.length === 0} className="manageInviteButtonStyle" variant="outline-primary" size="sm" onClick={this.handleRemoveNames}>
                -
              </Button>
            </div>

            <div className="form-row">
              <div className="col">
                <Form.Group controlId="email">
                  <Form.Label>{this.language === 'en' ? 'E-mail address' : 'Endereço e-mail'}</Form.Label>
                  <Form.Control defaultValue={this.state.email} onChange={this.handleEmailChange} type="email" name="email" placeholder={this.language === 'en' ? 'example@domain.com' : 'exemplo@dominio.com'} />
                </Form.Group>
              </div>
              {/* <div className="col"></div> */}
            </div>
            <div className="form-row">
              <div className="col">
                <Form.Group controlId="isGoing">
                  <label>{this.language === 'en' ? 'Are you joining us?' : 'Contamos contigo?'}</label>
                  <select onChange={this.handleIsGoingChange} id="isGoing" name="isGoing" className="form-control" defaultValue="none">
                    <option disabled value="none">
                      {this.language === 'en' ? 'Choose' : 'Escolha'}
                    </option>
                    <option value="true">{this.language === 'en' ? 'Yes' : 'Sim'}</option>
                    <option value="false">{this.language === 'en' ? 'No' : 'Não'}</option>
                  </select>
                </Form.Group>
              </div>
              <div className="col"></div>
            </div>
            {this.state.isGoing === true ? (
              <div>
                <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="adress">
                      <Form.Label>{this.language === 'en' ? 'Address' : 'Morada'}</Form.Label>
                      <Form.Control onChange={this.handleAddressChange} name="address" placeholder={this.language === 'en' ? 'Add address' : 'Adicionar endereço'} />
                    </Form.Group>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="city">
                      <Form.Label>{this.language === 'en' ? 'City' : 'Cidade'}</Form.Label>
                      <Form.Control onChange={this.handleCityChange} name="city" placeholder={this.language === 'en' ? 'Add city' : 'Adicionar cidade'} />
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group controlId="postalCode">
                      <Form.Label>{this.language === 'en' ? 'Postal Code' : 'Código Postal'}</Form.Label>
                      <Form.Control name="postalCode" onChange={this.handlePostalCodeChange} placeholder={this.language === 'en' ? '1234 AB' : '0000-000'} />
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="isGoing">
                      <label>{this.language === 'en' ? 'Country' : 'País'}</label>
                      <select onChange={this.handleCountryChange} id="country" name="country" className="form-control" defaultValue="none">
                        <option disabled value="none">
                          {this.language === 'en' ? 'Choose' : 'Escolha'}
                        </option>
                        {countries.map((c) => {
                          return (
                            <option key={c} value={c}>
                              {c}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Group>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="foodText">
                      <textarea value={this.state.foodText} className="textAreaStyle" placeholder={this.language === 'en' ? 'If you have any food allergies or restrictions, please mention them here. (Optional)' : 'Caso tenha alergias ou intolerâncias, especifique-as aqui por favor. (Opcional)'} onChange={this.handleFoodTextChange} name="foodText" />
                    </Form.Group>
                  </div>
                  {/* <div className="col"></div> */}
                </div>
                {/* <div className="form-row">
                  <div className="col">
                    <Form.Group controlId="songText">
                      <textarea
                        value={this.state.songText}
                        className="textAreaStyle"
                        placeholder={
                          this.language === "en"
                            ? "What is the song that always gets you going? (one song per person)"
                            : "Qual é a música que te faz levantar da cadeira? (uma música por pessoa)"
                        }
                        onChange={this.handleSongTextChange}
                        name="songText"
                      />
                    </Form.Group>
                  </div>
                </div> */}
              </div>
            ) : (
              <div></div>
            )}

            {/* <p style={{ color: "grey" }}>
              <i>
                {this.language === "en"
                  ? "All the fields are required."
                  : "Todos os campos são obrigatórios"}
              </i>
            </p> */}
            <Button disabled={this.state.sendStatus !== waitingStatus || this.isSubmitDisabled()} variant="primary" type="submit" className='submit-button'>
              {this.renderSubmitButton()}
            </Button>
          </Form>
        </section>
      </div>
    );
  }
}

export default Confirmation;
