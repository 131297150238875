import React from 'react';
import styles from './burgerMenu.module.css';

const BurgerMenu = (props) => {
  let homeText = 'Início';
  // let whereToStayText = "";
  let routePrefix = '';
  let inviteText = 'Convite';
  let giftsText = 'Lista de Casamento';
  // let instaPhotos = 'Fotos Instantâneas';

  return (
    <div className={`${props.open ? styles.burgerMenuOpen : styles.burgerMenuClosed}`}>
      <a className={`${styles.menuItem} normalFontStyle`} href={routePrefix + '/home'}>
        <b>{homeText}</b>
      </a>
      {/* <a id="invite" className={`${styles.menuItem} normalFontStyle`} href="/invite">
          Convite
        </a> */}
      <a className={`${styles.menuItem} normalFontStyle`} href={routePrefix + '/invite'}>
        <b>{inviteText}</b>
      </a>
      {/* <a className={`${styles.menuItem} normalFontStyle`} href={routePrefix + "/toStay"}>
        <b>{whereToStayText}</b>
      </a> */}
      <a className={`${styles.menuItem} normalFontStyle`} href={routePrefix + '/gifts'}>
        <b>{giftsText}</b>
      </a>
      {/* <a className={`${styles.menuItem} normalFontStyle`} href={routePrefix + '/instaPhotos'}>
        <b>{instaPhotos}</b>
      </a> */}
      <a
        className={`${styles.menuItem} normalFontStyle`}
        href={routePrefix + "/confirmation"}
      >
        <b>RSVP</b>
      </a>
    </div>
  );
};

export default BurgerMenu;
